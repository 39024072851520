import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Logout,
  useLocaleState,
  UserMenu,
  useUserMenu,
  useRefresh
} from "react-admin";
import { Link, useLocation } from "react-router-dom";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle
} from "@mui/material";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import PersonIcon from "@mui/icons-material/Person";
import Notification from "../../pages/Notification";
import { Box, Button, Typography } from "@mui/material";
import logo from "../../styles/logo/emsd.png";
import palette from "../../styles/palette";

import { useTranslation } from 'react-i18next';
import i18n from "../../../i18n";


// It's important to pass the ref to allow MUI to manage the keyboard navigation
const ConfigurationMenu = React.forwardRef((props, ref) => {
  const { t : itranslate } = useTranslation();
  
  return (
    <MenuItem
      ref={ref}
      component={Link}
      // It's important to pass the props to allow MUI to manage the keyboard navigation
      {...props}
      to="/configuration"
    >
      <ListItemIcon>
        <SettingsOutlinedIcon />
      </ListItemIcon>
      <ListItemText>
        {itranslate("Settings")}
      </ListItemText>
    </MenuItem>
  );
});


const SwitchLanguage = React.forwardRef((props, ref) => {
  const { t : itranslate } = useTranslation();
  const [locale, setLocale] = useLocaleState();
  const { onClose } = useUserMenu();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set anchor to open menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Close menu
  };

  const handleLanguageChange = (language) => {
    setLocale(language);
    i18n.changeLanguage(language);
    handleClose(); // Close the menu after selection
    onClose(); 
    window.location.reload();
  };

  return (
    <>
      <MenuItem
        ref={ref}
        {...props}
        sx={{ color: "text.secondary" }}
        onClick={handleClick} // Open the menu instead of a dialog
      >
        <ListItemIcon sx={{ minWidth: 5 }}>
          <LanguageIcon />
        </ListItemIcon>
        <ListItemText>
          {itranslate("Switch Language")}
        </ListItemText>
      </MenuItem>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleLanguageChange('en')}>
          {itranslate("English")}
        </MenuItem>
        <MenuItem onClick={() => handleLanguageChange('ch')}>
          {itranslate("Chinese")}
        </MenuItem>
      </Menu>
    </>
  );
});




const MyUserMenu = (props) => {
  return (
    <UserMenu {...props} icon={<PersonIcon />}>
      <ConfigurationMenu label="g" />
      {/* { process.env.REACT_APP_UAT && <SwitchLanguage />} */}
      <Logout />
    </UserMenu>
  );
};


export const MyAppBar = (props) => {
  const { t : itranslate } = useTranslation();

  const [locale, setLocale] = useLocaleState();

  // const [anchorEl, setAnchorEl] = useState(null);

  // const handleClick = (event) => setAnchorEl(event.currentTarget);
  
  // const handleClose = (language) => {
  //   if (language) handleLanguageChange(language);
  //   setAnchorEl(null);
  // };


  const handleLanguageChange = (language) => {
    setLocale(language);
    i18n.changeLanguage(language);
    window.location.reload();
  };

  const location = useLocation();
  const handleFontSize = (fontSize) => {
    let root = document.documentElement;
    root.style.fontSize = `${fontSize}px`;
  };

  return (
    <AppBar
      {...props}
      userMenu={<MyUserMenu />}
      sx={{
        // nonce: window.__CSP_NONCE__,
        boxShadow: "none",
        background: "white",
        borderBottom: `1.5px solid ${palette.border}`,
        color: "black",
        height: "60px",
        display: "flex",
        justifyContent: "center",

        "& .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeLarge.RaLoadingIndicator-loadedIcon ":
          {
            pointerEvents: "none",
            // background: "yellow"
          },
      }}
    >
      <Box flex="1" sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
        <img src={logo} className="w-[150px] mr-[15px]" alt="logo" />

        <Typography
          variant="h6"
          id="react-admin-title"
          sx={{ fontFamily: "Frutiger_bold" }}
        >
          {window.location.pathname === "/lift_rc"
            ? `${itranslate("TAPAS Registered Contractor Portal")}    `
            : `${itranslate("TAPAS EMSD Portal")}     `}
        </Typography>
      </Box>

      {!location.pathname.includes("applications_new") &&
        window.location.pathname === "/lift_rc" && (
          <Button
            sx={{
              backgroundColor: `${palette.primary}`,
              fontWeight: "600",
              margin: "0px 15px",
            }}
            component={Link}
            to="/applications_new"
            variant="contained"
            key="button"
          >
            + {itranslate("New Application")}
          </Button>
        )}

      <Typography sx={{ fontSize: "1.25rem" }} id="react-admin-title">
        {itranslate("version")} 2.1.1
      </Typography>

      <ButtonGroup>
        <IconButton
          onClick={() => handleFontSize(14)}
          sx={{ fontSize: "1.2rem", height: "40px", width: "40px" }}
        >
          A
        </IconButton>
        <IconButton
          onClick={() => handleFontSize(16)}
          sx={{ fontSize: "1.5rem", height: "40px", width: "40px" }}
        >
          A
        </IconButton>
        <IconButton
          onClick={() => handleFontSize(18)}
          sx={{ height: "40px", width: "40px" }}
        >
          A
        </IconButton>
      </ButtonGroup>

      {process.env.REACT_APP_UAT && (
        <>
          {locale === "en" ? (
            <IconButton
              sx={{ fontSize: "1.2rem" }}
              onClick={() => handleLanguageChange("ch")}
            >
              {/* {itranslate("繁")} */}
              繁
            </IconButton>
          ) : (
            <IconButton
              sx={{ fontSize: "1.2rem" }}
              onClick={() => handleLanguageChange("en")}
            >
              {/* {itranslate("Eng")} */}
              Eng
            </IconButton>
          )}
        </>
      )}

      {/* { process.env.REACT_APP_UAT && 
            <Box>
              <Button sx={{ color: "black", fontSize: "1.2rem" }} onClick={handleClick}>
                {itranslate("Select Language")}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
              >
                <MenuItem onClick={() => handleClose("en")}>{itranslate("English")}</MenuItem>
                <MenuItem onClick={() => handleClose("ch")}>{itranslate("Chinese")}</MenuItem>
              </Menu>
            </Box>
          } */}

      <Notification />
    </AppBar>
  );
};
