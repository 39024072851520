import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import EditableSelect from "../../utils/certEditUtils/EditableSelect";
import mandatoryCertData from "../../utils/certEditUtils/mandatoryCertData";
import CertFieldAdder from "../../utils/certEditUtils/CertFieldAdder";
import keyToField from "../../utils/keyToField";
import { useTranslation } from 'react-i18next';


const CertEditArea = (props) => {
  const {
    application,
    cert,
    setHash,
    certificates,
    setCertificates,
    makeCorrections,
    simpleDisplay,
    deleteField,
    userInfo,
    userRoles,
  } = props;
    // console.log("🚀 ~ CertEditArea ~ application:", application)

  const { t : itranslate } = useTranslation();

  // Engineer will have "View Only" to appendix, if "application is Pending and ReturningClarifying" and "if application is Pending E and user does not have the correct role""
  const isDisabled =
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "ReturningClarifying") ||
  (userInfo?.role === "EMSDEngineer" && application?.status === "Pending E" && !userRoles.includes(application?.current_role_id))


  // Check if the field has been edited. If so, the ceritificate will have the related field in the "cert_corrections" object, and will return true.
  const checkIfEdited = (certificateId, field) => {
    if (certificates && certificates.length > 0) {
      const correction = certificates.filter(
        (correction) => correction.id === certificateId
      )[0];

      if (
        correction?.cert_corrections?.[field] ||
        correction?.cert_corrections?.[field] === ""
      )
        return true;
      else if (correction?.cert_type_metadata?.[field]) return false;
      else return false;
    }
  };

  // Generate a list of all ai result of a single field including unused AI results
  const aiOptions = (cert, field) => {
    try {
      let options = [cert.cert_type_metadata[field]]; // Default chosen option
      cert.unUsedAiResult.forEach((aiResult) => {
        if (
          aiResult.label === field &&
          aiResult.text !== cert.cert_type_metadata[field]
        ) {
          options.push(aiResult.text);
        }
      });
      return options;
    } catch (error) {
      return [];
    }
  };

  const handleDelete = (field) => {
    deleteField(cert.id, field);
  };

  const handleSelect = (field, value) => {
    //Three case: If the value is the default value (cert_type_metadata[field]), then the position_data should be the original position_data
    //If the value is not the default value, then it is in unUsedAiResult
    //But if the value is edited by user, it is no where to be found, so undefined
    if (cert.unUsedAiResult) {
      const newPosition =
        cert.unUsedAiResult?.find((aiResult) => aiResult.label === field && aiResult.text === value)?.position ||
        cert.position_data?.find((item) => item.comment.text === field)?.position;

      setHash("");
      makeCorrections(value, cert.id, field, newPosition);
    }
  };

  const scrollToPosition = (field) => {
    const label = cert?.position_data?.find((result) => result.comment.text === field);
    if (label) setHash(label.id);
  };

  const isMandatory = (mode, cert, field) => {
    //Two mode
    //mode === "boolean" return true or false
    //mode === "list" return a list of mandatory field
    try {
      let result = false;
      let mandatoryFieldList = [];
      if (cert.type === "ComponentTypeCert") {
        function getComponentType() {
          for (const compType of Object.keys(application.app_type.components)) {
            const matchComp = application.app_type.components[compType].find((comp) => comp.type_test_certificate_id.some((certId) => certId === cert.id));
            if (matchComp) return compType;
          }
        }
        const componentType = getComponentType();

        result = mandatoryCertData[componentType].includes(field) || mandatoryCertData.general.includes(field);

        if (mode === "list") mandatoryFieldList = mandatoryCertData[componentType].concat(mandatoryCertData.general);

      } else if (cert.type === "ModelTypeCert") {
        function getModel() {
          for (const model of application.model) {
            const matchFile = model.manufacturer_files.find((file) => file.type_test_certificate_id.some((certId) => certId === cert.id));
            if (matchFile) return model;
          }
        }
        const modelType = getModel().type;
        const leType = getModel().LE_type === "L" ? "lift" : "escalator";

        result =
          mandatoryCertData[modelType].includes(field) ||
          mandatoryCertData[leType].includes(field) ||
          mandatoryCertData.general.includes(field);

        if (mode === "list")
          mandatoryFieldList = mandatoryCertData[modelType]
            .concat(mandatoryCertData[leType])
            .concat(mandatoryCertData.general);

      } else if (cert.type === "ISO") {
        result = mandatoryCertData["iso"].includes(field);
        if (mode === "list") mandatoryFieldList = mandatoryCertData["iso"];
      }

      return mode === "boolean" ? result : mandatoryFieldList;
    } catch (error) {
      return mode === "boolean" ? false : [];
    }
  };

  const displayedCertFields = (cert) => {
    try {
      const certInCorrections = certificates.find((tec) => tec.id === cert.id);
      const certField = Object.keys(cert.cert_type_metadata || {})
        .filter((field) => cert.cert_type_metadata[field] !== "")
        .concat(Object.keys(certInCorrections.cert_corrections || {}))
        .concat(isMandatory("list", cert));

      //The Set object only contains unique values, so any duplicates are automatically removed
      return Array.from(new Set(certField)).sort((a, b) => {
        const general = cert.type === "ISO" ? "iso" : "general";
        const aIsGeneral = mandatoryCertData[general].includes(a);
        const bIsGeneral = mandatoryCertData[general].includes(b);
        const aIsMandatory = isMandatory("boolean", cert, a);
        const bIsMandatory = isMandatory("boolean", cert, b);
        //general field > mandatory field > other field
        if (aIsGeneral && !bIsGeneral) return -1;
        if (!aIsGeneral && bIsGeneral) return 1;
        if (aIsMandatory && !bIsMandatory) return -1;
        if (!aIsMandatory && bIsMandatory) return 1;
        return a.localeCompare(b);
      });
    } catch (error) {
      // when cert_type_metadata is undefined
      return [];
    }
  };

  const checkLiftStandard = (application) =>
    [
      "HydraulicLift",
      "ElectricTractionLiftMachineRoom",
      "ElectricTractionLiftRoomless",
      "SafetyComponent",
      // "MVPS"
    ].includes(application.app_type?.type);

  const checkEscalatorStandard = (application) => ["Escalator", "PassengerConveyor"].includes(application.app_type?.type);

  if (Object.keys(cert || {}).length === 0 || !application) return null;

  return (
    <div
      className="h-2/5 overflow-y-scroll"
    >
      <div
        className="bg-[#d5f0ff82] w-full flex items-center justify-between py-1"
      >
        <Box sx={{ pl: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>{cert?.filename}</Typography>
          {(checkLiftStandard(application) ||
            checkEscalatorStandard(application)) && (
              <FormControlLabel
                disabled={isDisabled} 
                checked={
                  checkEscalatorStandard(application)
                    ? cert.comply_standard?.["escalator"] || false
                    : cert.comply_standard?.["lift"] || false
                }
                onChange={(e) => {
                  makeCorrections(
                    {
                      ...(cert?.comply_standard || {}),
                      ...(checkEscalatorStandard(application) && {
                        escalator: e.target.checked,
                      }),
                      ...(checkLiftStandard(application) && {
                        lift: e.target.checked,
                      }),
                    },
                    cert.id,
                    "comply_standard"
                  );
                }}
                label={itranslate("Comply Standard")}
                control={<Checkbox />}
              />
            )}

          {cert?.historical && (
            <Typography sx={{ color: "red" }}>
              {itranslate("Previously Approved")}
            </Typography>
          )}

        </Box>

        {!cert?.historical && (
          <CertFieldAdder 
            certId={cert.id} 
            makeCorrections={makeCorrections}
            application={application}
            userInfo={userInfo}
            userRoles={userRoles}
          />
        )}

      </div>

      <Grid container spacing={2} sx={{ padding: "10px" }}>
        {displayedCertFields(cert).map((field, index) => {
          if (
            cert.cert_type_metadata?.[field] ||
            cert?.cert_corrections?.[field] ||
            isMandatory("boolean", cert, field)
          ) {
            return cert?.historical ? (
              <Grid
                item
                xs={12}
                key={`${cert.id}-${cert.type}-${field}`}
                sx={{ ml: 1 }}
              >
                <Typography>
                  {/* EMSD requirement: do not translate the field if field is not mandatory */}
                  {!isMandatory("boolean", cert, field)
                    ? itranslate(field)
                    : itranslate(keyToField[field]) || itranslate(field)}
                    : {itranslate(simpleDisplay(cert.id, field))}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={6} key={`${cert.id}-${cert.type}-${field}`}>
                <EditableSelect
                  field={field}
                  options={aiOptions(cert, field)}
                  value={simpleDisplay(cert.id, field)}
                  handleSelect={handleSelect}
                  handleDelete={handleDelete}
                  handleInput={(e) => makeCorrections(e.target.value, cert.id, field)}
                  scrollToPosition={() => scrollToPosition(field)}
                  isMandatory={isMandatory("boolean", cert, field)}
                  checkIfEdited={checkIfEdited(cert.id, field)}
                  application={application}
                  userInfo={userInfo}
                  userRoles={userRoles}
                  cert={cert}
                  certificates={certificates}
                  setCertificates={setCertificates}
                />
              </Grid>
            );
          } else return null;
        })}
      </Grid>
    </div>
  );
};

export default CertEditArea;
